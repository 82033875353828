import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { sortArray } from 'magmel';

// components
import { Navbar, Gallery, Banner, Footer } from 'src/components/common';
import { SectionHeader, ShootPage } from 'src/components/Photography';

const Moet = () => {
  return (
    <>
      <ShootPage
        bg="white"
        style={{
          marginTop: '100px',
        }}
      >
        <Navbar dark />
        <div className="container-lg">
          <SectionHeader sectionName="ladlink intl sch" />
        </div>
        <Grid />
        <a class="ca3-scroll-down-link ca3-scroll-down-arrow" />
      </ShootPage>

      <Banner />
      <Footer />
    </>
  );
};

const Grid = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativePath: { regex: "/photography/ads/ladlink-intl-sch/" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                  originalImg
                }
              }
            }
          }
        }
      `}
      render={({ allFile }) => {
        const sortedArray = sortArray({
          array: allFile.nodes,
          order: 'asce',
          key: 'name',
        });

        return (
          <Gallery
            itemsPerRow={[2, 3]}
            classNames="full-width"
            images={sortedArray.map((file, index) => {
              return {
                id: String(index),
                ...file.childImageSharp.fluid,
                caption: 'gallery-image',
              };
            })}
          />
        );
        // return (
        //   <div className="horizontal-grid">
        //     <div className="horizontal-full hs">
        //       {sortedArray.map((file, index) => {
        //         return (
        //           <Img
        //             className="horizontal-image portrait"
        //             fluid={file.childImageSharp.fluid}
        //             key={index}
        //           />
        //         );
        //       })}
        //     </div>
        //   </div>
        // );
      }}
    />
  );
};

export default Moet;
